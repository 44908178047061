import { FunctionComponent } from 'react';
import { SpotActivityType } from '../../../../model';

import styles from './CurbSpaceActivityTypeIcon.module.scss';

interface Props {
  className?: string;
  type: SpotActivityType;
}

export const CurbSpaceActivityTypeIcon: FunctionComponent<Props> = ({ className, type }) => {
  return <span className={`${className || ''} ${styles.icon} type-${SpotActivityType[type]}`}></span>;
};
