import { StreetObjectType } from '../../model';
import { getApi } from './api';

const BASE_URL = '/GeoIndexV2';

const getCount = (layerName: string) => {
  return getApi()
    .get<{ Value: number }>(`${BASE_URL}/${layerName}/count`)
    .then((response) => response.data.Value);
};

export const assetsGeo = {
  getEVChargingStationCount: () => getCount(StreetObjectType.EVChargingStation),
};
