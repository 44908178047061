import { getApi } from './api';
import { ITransaction } from '../../model/api/meter-transactions';

const BASE_URL = '/transaction';

const getTopTransactions = (query: string) =>
  getApi()
    .get<Array<ITransaction>>(`${BASE_URL}/${query}`)
    .then((response) => response.data || [])
    .then((transactions) =>
      transactions.map((x) => ({
        ...x,
        SessionStart: new Date(x.SessionStart),
        SessionEnd: x.SessionEnd ? new Date(x.SessionEnd) : null,
      })),
    );

const getTopTransactionsByMeter = (meterId: number) => getTopTransactions(`by-meter?id=${meterId}`);

const getTopSpotTransactions = (spotId: number) => getTopTransactions(`by-spot?id=${spotId}`);

export const transactions = {
  getTopMeterTransactions: getTopTransactionsByMeter,
  getTopSpotTransactions,
};
