import { Action, AnyAction } from '@reduxjs/toolkit';
import { from, Observable, of, zip } from 'rxjs';
import { map, filter, concatMap, catchError, mergeMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { GeoJsonProperties, FeatureCollection } from 'geojson';
import { Feature, Geometry } from 'geojson';

import { assetsGeo, streetObjects } from '../../../../services';
import { citiesActions } from '../../../common';
import { MO_PARKING_SIGNS, RESERVED_SIGNS, StreetObjectType, TagFilter, TIME_LIMIT_SIGNS } from '../../../../model';
import { selectedStreetSignsActions, streetObjectsGeoActions, streetObjectsLayerActions } from '..';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { mapStateActions } from '../../map-state';
import { store } from '../../../../store';

const fetchStreetObjectsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(streetObjectsGeoActions.fetch.match),
    concatMap((action) =>
      loadData(action.payload.cityCode).pipe(
        map((x) => processData(x, action.payload.types)),
        map(([fireHydrants, signs, curbs, curbCuts, poles]) =>
          streetObjectsGeoActions.fetchSuccess({ signs, curbs, curbCuts, fireHydrants, poles }),
        ),
        catchError((err) => of(streetObjectsGeoActions.fetchFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    filter((action) => action.payload?.Code !== undefined),
    mergeMap((action) =>
      of(
        // streetObjectsLayerActions.setDisabled(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        streetObjectsLayerActions.fetchTypes({ cityCode: action.payload!.Code }),
        selectedStreetSignsActions.collapsePopups(),
      ),
    ),
  );

const fetchTypesEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(streetObjectsLayerActions.fetchTypes.match),
    concatMap((action) =>
      zip(loadData(action.payload.cityCode), from(assetsGeo.getEVChargingStationCount())).pipe(
        mergeMap(([streetObjects, evCount]) => {
          const streetObjectsTypes = extractTypes(streetObjects);
          return of(streetObjectsLayerActions.fetchTypesSuccess({ types: streetObjectsTypes, evCount: evCount }));
        }),
        catchError((err) => of(streetObjectsLayerActions.fetchTypesFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedStreetSignsActions.closePopups()),
  );

const loadStreetSignEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(selectedStreetSignsActions.loadSign.match),
    map((action) => {
      const feature = findSignFeature(action.payload.id);
      const properties = feature?.properties;

      if (properties && action.payload.position) {
        const position: [number, number] = [action.payload.position[0], action.payload.position[1]];
        const initPosition = action.payload.initPosition;

        return selectedStreetSignsActions.selectSign({
          sign: {
            Id: properties.id,
            Code: properties.signCode,
            Position: position,
            Direction: properties.direction,
            Description: properties.signCodeDesc,
            CollectionDate: new Date(properties.collectionDate),
            ImageUrl: properties.imageURL,
          },
          position: position,
          initPosition: initPosition ? initPosition : position,
        });
      }

      return selectedStreetSignsActions.loadFailed({ id: action.payload.id, error: 'Not found' });
    }),
  );

export const streetObjectsEpic = combineEpics<AnyAction>(
  fetchStreetObjectsEpic,
  citySelectedEpic,
  fetchTypesEpic,
  closePopupsEpic,
  loadStreetSignEpic,
);

function loadData(
  cityCode: string,
): Observable<[FeatureCollection, FeatureCollection, FeatureCollection, FeatureCollection, FeatureCollection]> {
  return zip(
    from(streetObjects.listHydrants(cityCode)),
    from(streetObjects.listSigns(cityCode)),
    from(streetObjects.listCurbs(cityCode)),
    from(streetObjects.listCurbCuts(cityCode)),
    from(streetObjects.listPoles(cityCode)),
  );
}

function processData(
  x: [FeatureCollection, FeatureCollection, FeatureCollection, FeatureCollection, FeatureCollection],
  filter: TagFilter | null,
): [FeatureCollection, FeatureCollection, FeatureCollection, FeatureCollection, FeatureCollection] {
  const hydrants = filter ? applyTypeFilter(filter, StreetObjectType.FireHydrant, x[0]) : x[0];
  const curbs = filter ? applyTypeFilter(filter, StreetObjectType.Curb, x[2]) : x[2];
  const curbCuts = filter ? applyTypeFilter(filter, StreetObjectType.CurbCut, x[3]) : x[3];
  const poles = filter ? applyTypeFilter(filter, StreetObjectType.Pole, x[4]) : x[4];

  const signs = filter
    ? applyPropsFilter(
        filter,
        (p, f) => {
          if (f.tags[StreetObjectType.TimeLimit] && TIME_LIMIT_SIGNS[p?.signCode]) {
            return true;
          }

          if (f.tags[StreetObjectType.NoParking] && MO_PARKING_SIGNS[p?.signCode]) {
            return true;
          }

          if (f.tags[StreetObjectType.Reserved] && RESERVED_SIGNS[p?.signCode]) {
            return true;
          }

          return false;
        },
        x[1],
      )
    : x[1];

  return [hydrants, signs, curbs, curbCuts, poles];
}

function applyTypeFilter(filter: TagFilter, type: string, data: FeatureCollection): FeatureCollection {
  if (filter.enabled && !filter.allTagsEnabled() && !filter.tags[type]) {
    return EMPTY_FEATURE_COLLECTION;
  }

  return data;
}

function applyPropsFilter(
  filter: TagFilter,
  featureChecker: (props: GeoJsonProperties, filter: TagFilter) => boolean,
  data: FeatureCollection,
): FeatureCollection {
  if (!filter.enabled || filter.allTagsEnabled()) {
    return data;
  }

  if (!featureChecker) {
    return EMPTY_FEATURE_COLLECTION;
  }

  const result: FeatureCollection = {
    type: data.type,
    features: data.features.filter((f) => featureChecker(f.properties, filter)),
  };

  return result;
}

function extractTypes(
  x: [FeatureCollection, FeatureCollection, FeatureCollection, FeatureCollection, FeatureCollection],
): Array<StreetObjectType> {
  const types = new Array<StreetObjectType>();
  countObjectsInGeoJSON(x[0]) && types.push(StreetObjectType.FireHydrant);
  countObjectsInGeoJSON(x[2]) && types.push(StreetObjectType.Curb);
  countObjectsInGeoJSON(x[3]) && types.push(StreetObjectType.CurbCut);
  countObjectsInGeoJSON(x[4]) && types.push(StreetObjectType.Pole);

  if (countObjectsInGeoJSON(x[1])) {
    types.push(StreetObjectType.TimeLimit);
    types.push(StreetObjectType.NoParking);
    types.push(StreetObjectType.Reserved);
  }

  return types;
}

function countObjectsInGeoJSON(geoJSON: FeatureCollection): number {
  return geoJSON.features.length;
}

const findSignFeature = (id: string): Feature<Geometry, GeoJsonProperties> | null => {
  const result = store.getState().streetObjectsGeo.signs.features.find((x) => x.properties?.id === id);
  return result || null;
};
