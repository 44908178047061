import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IActivityTypeCount,
  IOccupancyStatusCount,
  OccupancyStatus,
  SavedCurbSpaceLayer,
  SensorFilter,
  TagFilter,
} from '../../../../model';

import { RootState } from '../../../../store';
import { ICurbSpaceOccupancyStatusChange } from '../../../../services/signaling/signalr-processor';

type StatusCount = { [key in OccupancyStatus]: number };

const getEmptyStatusCount = () => ({ Unknown: 0, Vacant: 0, Occupied: 0, Booked: 0 });

export interface ICurbSpacesLayerState {
  enabled: boolean;
  policyTypeIds: Array<number>;
  policyTypesFilter: TagFilter;
  statusesFilter: TagFilter;
  count: number;
  sensorsCount: number;
  sensorsFilter: SensorFilter;
  statusCount: StatusCount;
  types: IActivityTypeCount[];
  typesFilter: TagFilter;
}

const initialState: ICurbSpacesLayerState = {
  enabled: false,
  policyTypeIds: [],
  policyTypesFilter: new TagFilter(true),
  statusesFilter: new TagFilter(true),
  count: 0,
  sensorsCount: 0,
  sensorsFilter: SensorFilter.WithSensors,
  statusCount: getEmptyStatusCount(),
  types: [],
  typesFilter: new TagFilter(false),
};

const slice = createSlice({
  name: 'curbSpacesLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setDisabled(state) {
      state.enabled = false;
    },

    fetchPolicyTypes(state) {},
    fetchPolicyTypesSuccess(state, action: PayloadAction<Array<number>>) {
      state.policyTypeIds = action.payload;
      state.policyTypesFilter = action.payload.length > 0 ? state.policyTypesFilter.enable(true) : new TagFilter(false);
    },
    fetchPolicyTypesFailed(state, action: PayloadAction<string>) {
      console.log(action);
    },

    setPolicyTypesFilter(state, action: PayloadAction<TagFilter>) {
      state.policyTypesFilter = action.payload;
    },

    fetchStatuses(state) {
      state.statusesFilter = state.statusesFilter.enable(true);
    },

    setStatusesFilter(state, action: PayloadAction<TagFilter>) {
      state.statusesFilter = action.payload;
    },

    fetchCount(state) {},
    fetchCountSuccess(state, action: PayloadAction<{ curbSpacesCount: number; sensorsCount: number }>) {
      state.count = action.payload.curbSpacesCount;
      state.sensorsCount = action.payload.sensorsCount;
      if (action.payload.sensorsCount === 0) {
        state.sensorsFilter = SensorFilter.All;
      }
    },
    fetchCountFailed(state, action: PayloadAction<string>) {
      state.count = 0;
      state.sensorsCount = 0;
      state.sensorsFilter = SensorFilter.All;
      console.log(action);
    },

    setSensorsFilter(state, action: PayloadAction<SensorFilter>) {
      state.sensorsFilter = action.payload;
    },

    fetchStatusCount(state) {},
    fetchStatusCountSuccess(state, action: PayloadAction<IOccupancyStatusCount[]>) {
      const statusCount: StatusCount = getEmptyStatusCount();
      for (const x of action.payload) {
        statusCount[x.Status] = x.Count;
      }
      state.statusCount = statusCount;
    },
    fetchStatusCountFailed(state, action: PayloadAction<string>) {
      state.statusCount = getEmptyStatusCount();
      console.log(action);
    },
    changeStatusCount(state, action: PayloadAction<ICurbSpaceOccupancyStatusChange>) {
      if (action.payload.previousStatus !== undefined && action.payload.previousStatus !== action.payload.status) {
        const statusCount = { ...state.statusCount };
        const previousCount = Object.values(statusCount).reduce((acc, current) => acc + current, 0);
        statusCount[action.payload.status] = statusCount[action.payload.status] + 1;
        statusCount[action.payload.previousStatus] = Math.max(statusCount[action.payload.previousStatus] - 1, 0);
        const count = Object.values(statusCount).reduce((acc, current) => acc + current, 0);
        if (previousCount === count) {
          state.statusCount = statusCount;
        }
      }
    },

    fetchTypes(state) {},
    fetchTypesSuccess(state, action: PayloadAction<IActivityTypeCount[]>) {
      state.types = action.payload;
      state.typesFilter = action.payload.length > 1 ? state.typesFilter.enable(true) : new TagFilter(false);
    },
    fetchTypesFailed(state, action: PayloadAction<string>) {
      console.error(action.payload);
    },
    setTypesFilter(state, action: PayloadAction<TagFilter>) {
      state.typesFilter = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedCurbSpaceLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.statusesFilter = TagFilter.fromSavedValue(action.payload.statusesFilter);
        state.policyTypesFilter = TagFilter.fromSavedValue(action.payload.policyTypesFilter);
        state.sensorsFilter = action.payload.sensorsFilter !== undefined ? action.payload.sensorsFilter : initialState.sensorsFilter;
        state.typesFilter =
          action.payload.typesFilter !== undefined ? TagFilter.fromSavedValue(action.payload.typesFilter) : initialState.typesFilter;
      }
    },
  },
});

// Actions
export const curbSpacesLayerActions = slice.actions;

// Selectors
export const selectCurbSpacesLayer = (state: RootState) => state.curbSpacesLayer;
export const selectCurbSpacesEnabled = (state: RootState) => state.curbSpacesLayer.enabled;
export const selectCurbSpacePolicyTypeIds = (state: RootState) => state.curbSpacesLayer.policyTypeIds;
export const selectCurbSpacePolicyTypesFilter = (state: RootState) => state.curbSpacesLayer.policyTypesFilter;
export const selectCurbSpaceStatusesFilter = (state: RootState) => state.curbSpacesLayer.statusesFilter;
export const selectCurbSpacesSensorsCount = (state: RootState) => state.curbSpacesLayer.sensorsCount;

// Reducer
export const curbSpacesLayerReducer = slice.reducer;
