import { DayOfWeek, Month, TimeSpans } from '../../api';
import { CdsTimePeriod } from './cds-time-period';

const getDayOfWeek = (dow: DayOfWeek): number => {
  switch (dow) {
    case DayOfWeek.Sunday:
      return 0;
    case DayOfWeek.Monday:
      return 1;
    case DayOfWeek.Tuesday:
      return 2;
    case DayOfWeek.Wednesday:
      return 3;
    case DayOfWeek.Thursday:
      return 4;
    case DayOfWeek.Friday:
      return 5;
    case DayOfWeek.Saturday:
      return 6;
  }
};

const getMonth = (month: Month): number => {
  switch (month) {
    case Month.January:
      return 0;
    case Month.February:
      return 1;
    case Month.March:
      return 2;
    case Month.April:
      return 3;
    case Month.May:
      return 4;
    case Month.June:
      return 5;
    case Month.July:
      return 6;
    case Month.August:
      return 7;
    case Month.September:
      return 8;
    case Month.October:
      return 9;
    case Month.November:
      return 10;
    case Month.December:
      return 11;
  }
};

const dateTimeInTimeSpan = (dateTime: Date, ts: TimeSpans) => {
  if (ts.StartDate && dateTime < ts.StartDate) {
    return false;
  }

  if (ts.EndDate && dateTime >= ts.EndDate) {
    return false;
  }

  const daysOfWeek = ts.DaysOfWeek?.map((x) => getDayOfWeek(x));
  if (daysOfWeek && daysOfWeek.length && !daysOfWeek.includes(dateTime.getDay())) {
    return false;
  }

  if (ts.DaysOfMonth && ts.DaysOfMonth.length && !ts.DaysOfMonth.includes(dateTime.getDate())) {
    return false;
  }

  const months = ts.Months?.map((x) => getMonth(x));
  if (months && months.length && !months.includes(dateTime.getMonth())) {
    return false;
  }

  if (ts.TimeOfDayStart || ts.TimeOfDayEnd) {
    const period = new CdsTimePeriod(ts.TimeOfDayStart, ts.TimeOfDayEnd);
    const inPeriod = period.dateInTimePeriod(dateTime);
    if (!inPeriod) {
      return false;
    }
  }

  return true;
};

const dateTimeInTimeSpans = (dateTime: Date, timeSpans: TimeSpans[]) => {
  for (const ts of timeSpans) {
    const inTimespan = dateTimeInTimeSpan(dateTime, ts);
    if (inTimespan) {
      return true;
    }
  }
  return false;
};

export const cdsDateUtils = {
  dateTimeInTimeSpans,
};
