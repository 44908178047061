import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedStreetObjectsLayer, StreetObjectType, TagFilter } from '../../../../model';

import { RootState } from '../../../../store';

export interface IStreetObjectsLayerState {
  available: boolean;
  enabled: boolean;
  types: Array<StreetObjectType>;
  typesFilter: TagFilter;
  evCount: number;
}

const initialState: IStreetObjectsLayerState = {
  available: true,
  enabled: false,
  types: [],
  typesFilter: new TagFilter(true),
  evCount: 0,
};

const slice = createSlice({
  name: 'streetObjectsLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setDisabled(state) {
      state.enabled = false;
    },

    fetchTypes(state, action: PayloadAction<{ cityCode: string }>) {},
    fetchTypesSuccess(state, action: PayloadAction<{ types: Array<StreetObjectType>; evCount: number }>) {
      const types = action.payload.types;
      if (action.payload.evCount > 0) types.push(StreetObjectType.EVChargingStation);

      state.types = types;
      state.typesFilter = types.length > 0 ? state.typesFilter.enable(true) : new TagFilter(false);
      state.available = types.length > 0;
      state.evCount = action.payload.evCount;
    },
    fetchTypesFailed(state, action: PayloadAction<string>) {
      console.log(action);
    },

    setTypesFilter(state, action: PayloadAction<TagFilter>) {
      state.typesFilter = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedStreetObjectsLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.typesFilter = TagFilter.fromSavedValue(action.payload.typesFilter);
      }
    },
  },
});

// Actions
export const streetObjectsLayerActions = slice.actions;

// Selectors
export const selectStreetObjectsLayer = (state: RootState) => state.streetObjectsLayer;

// Reducer
export const streetObjectsLayerReducer = slice.reducer;
