export enum StreetObjectType {
  FireHydrant = 'FireHydrant',
  Curb = 'Curb',
  CurbCut = 'CurbCut',
  TimeLimit = 'TimeLimit',
  NoParking = 'NoParking',
  Reserved = 'Reserved',
  Pole = 'Pole',
  EVChargingStation = 'EVChargingStation',
}

export interface ISelectedStreetSign {
  Id: string;
  Position: [number, number];
  Code: string;
  Description: string;
  Direction: string;
  CollectionDate: Date;
  ImageUrl: string;
}

export interface ISignByCode {
  [code: string]: boolean;
}

export const TIME_LIMIT_SIGNS: ISignByCode = {
  'R7-108': true,
};

export const RESERVED_SIGNS: ISignByCode = {
  'R7-8': true,
};

export const MO_PARKING_SIGNS: ISignByCode = {
  'R7-1': true,
  'R7-2': true,
  'R7-4': true,
  'R7-6': true,
  'R7-201': true,
  'R7-203': true,
  'R8-3': true,
  'R8-3A': true,
};

export const signObjectType = (code: string | undefined): StreetObjectType | null => {
  if (!code) return null;

  if (TIME_LIMIT_SIGNS[code]) return StreetObjectType.TimeLimit;
  if (RESERVED_SIGNS[code]) return StreetObjectType.Reserved;
  if (MO_PARKING_SIGNS[code]) return StreetObjectType.NoParking;
  return null;
};
