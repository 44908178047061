import { getApi } from './api';
import { IActivityTypeCount, IOccupancyStatusCount, ISpot, ISpotRegulation, ISpotState } from '../../model';

const BASE_URL = '/spot';

const get = async (id: number): Promise<ISpot> => {
  return await getApi()
    .get<ISpot>(`${BASE_URL}/${id}`)
    .then((response) => response.data);
};

const getSpotState = async (id: number): Promise<Array<ISpotState>> => {
  return await getApi()
    .get<Array<ISpotState>>(`${BASE_URL}/state/?id=${id}`)
    .then((response) => response.data || []);
};

const getZoneSpotsStates = async (zoneId: number): Promise<Array<ISpotState>> => {
  return await getApi()
    .get<Array<ISpotState>>(`${BASE_URL}/state/by-zone/?id=${zoneId}`)
    .then((response) => response.data || []);
};

const getSpotsStates = async (spots: Array<number>): Promise<Array<ISpotState>> => {
  const ids = spots.map((x) => `id=${x}`).join('&');
  return await getApi()
    .get<Array<ISpotState>>(`${BASE_URL}/state?${ids}`)
    .then((response) => response.data || []);
};

const getSpotRegulation = async (spotId: number): Promise<ISpotRegulation | null> => {
  /*
  return await getApi({ ignoreErrorStatuses: getSpotRegulationAllowedErrors })
    .get<ISpotRegulation>(`${BASE_URL}/${spotId}/regulation`)
    .then((response) => response.data)
    .then((regulation) => {
      if (!regulation) return null;

      (regulation.Schedules || []).forEach((schedule) => {
        schedule.Items.forEach((i: IScheduleItem) => {
          if (i.ToTime) i.ToTime = i.ToTime.split(':')[0] + ':' + i.ToTime.split(':')[1];
          if (i.FromTime) i.FromTime = i.FromTime.split(':')[0] + ':' + i.FromTime.split(':')[1];

          if (i.Rates) {
            i.Rates.forEach((r: IScheduleItemRate) => {
              if (r.ToTime) r.ToTime = r.ToTime.split(':')[0] + ':' + r.ToTime.split(':')[1];
              if (r.FromTime) r.FromTime = r.FromTime.split(':')[0] + ':' + r.FromTime.split(':')[1];
            });
          }
        });
      });

      return regulation;
    })
    .catch((err: AxiosError) => {
      if (getSpotRegulationAllowedErrors.includes(err.response?.status || 0)) {
        return null;
      }

      throw err;
    });
  */
  return Promise.resolve(null);
};

const getSpotPolicyTypes = async (): Promise<Array<number>> => {
  return await getApi()
    .get<Array<number>>(`${BASE_URL}/policy-types`)
    .then((response) => response.data || []);
};

const getCount = async (): Promise<number> => {
  return await getApi()
    .get<{ Count: number }>(`${BASE_URL}/count`)
    .then((response) => response.data.Count);
};

const getStudyAreaSpotsStates = async (studyAreaId: number): Promise<Array<ISpotState>> => {
  return await getApi()
    .get<Array<ISpotState>>(`${BASE_URL}/state/by-study-area/?id=${studyAreaId}`)
    .then((response) => response.data || []);
};

const getBlockfaceSpotsStates = async (blockfaceId: number): Promise<Array<ISpotState>> => {
  return await getApi()
    .get<Array<ISpotState>>(`${BASE_URL}/state/by-blockface/?id=${blockfaceId}`)
    .then((response) => response.data || []);
};

const getStatusCount = (): Promise<IOccupancyStatusCount[]> =>
  getApi()
    .get<IOccupancyStatusCount[]>(`${BASE_URL}/status-count`)
    .then((x) => x.data || []);

const getActivityTypeCount = (): Promise<IActivityTypeCount[]> =>
  getApi()
    .get<IActivityTypeCount[]>(`${BASE_URL}/activity-type-count`)
    .then((x) => x.data || []);

export const spots = {
  get,
  getSpotState,
  getZoneSpotsStates,
  getSpotsStates,
  getSpotRegulation,
  getSpotPolicyTypes,
  getCount,
  getStudyAreaSpotsStates,
  getBlockfaceSpotsStates,
  getStatusCount,
  getActivityTypeCount,
};
