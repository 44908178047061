import { getApi } from './api';
import { ICity } from '../../model';

export enum CityCode {
  Louisville = 'LSV',
  SanFrancisco = 'SFC',
  Arlington = 'ARL',
  SanJose = 'SJC',
  Minneapolis = 'MPL',
  Seattle = 'STL',
}

// city bounding box SW/NE
interface ICityBoundingBox {
  [k: string]: [[number, number], [number, number]];
}
const CITY_BOUNDING_BOXES: ICityBoundingBox = {
  [CityCode.Louisville]: [
    [-85.79760942761706, 38.20094795238043],
    [-85.69308157741803, 38.27234921840122],
  ],
  [CityCode.SanFrancisco]: [
    [-122.49098966051903, 37.71190142810613],
    [-122.37796334432889, 37.81558315050306],
  ],
  [CityCode.Arlington]: [
    [-77.17, 38.83],
    [-77.04, 38.91],
  ],
  [CityCode.SanJose]: [
    [-122.032, 37.206],
    [-121.75, 37.413],
  ],
  [CityCode.Minneapolis]: [
    [-93.429, 44.866],
    [-93.032, 45.194],
  ],
  [CityCode.Seattle]: [
    [-122.368855, 47.60379],
    [-122.320876, 47.62514],
  ],
};

const getAll = (): Promise<Array<ICity>> => {
  return getApi({ ignoreTenant: true })
    .get<Array<ICity>>('/city')
    .then((response) => response.data)
    .then((items) =>
      items.map((x) => ({
        ...x,
        CreatedAt: new Date(x.CreatedAt),
        UpdatedAt: x.UpdatedAt ? new Date(x.UpdatedAt) : null,
        Bounds: CITY_BOUNDING_BOXES[x.Code] || null,
      })),
    );
};

export const cities = {
  getAll: getAll,
};
