export enum DayOfWeek {
  Monday = 'Mon',
  Tuesday = 'Tue',
  Wednesday = 'Wed',
  Thursday = 'Thu',
  Friday = 'Fri',
  Saturday = 'Sat',
  Sunday = 'Sun',
}

export enum Month {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export enum Activity {
  Parking = 'Parking',
  NoParking = 'NoParking',
  Loading = 'Loading',
  NoLoading = 'NoLoading',
  Unloading = 'Unloading',
  NoUnloading = 'NoUnloading',
  Stopping = 'Stopping ',
  NoStopping = 'NoStopping',
  Travel = 'Travel',
  NoTravel = 'NoTravel',
}

export enum UnitOfTime {
  Second = 'Second',
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export enum UserClass {
  Bicycle = 'Bicycle',
  Bus = 'Bus',
  CargoBicycle = 'CargoBicycle',
  Car = 'Car',
  Moped = 'Moped',
  Motorcycle = 'Motorcycle',
  Scooter = 'Scooter',
  Truck = 'Truck',
  Van = 'Van',

  HandicapAccessible = 'Handicap-Accessible',
  Human = 'Human',
  ElectricAssist = 'ElectricAssist',
  Electric = 'Electric',
  Combustion = 'Combustion',
  Autonomous = 'Autonomous',
  Purpose = 'Purpose',

  Construction = 'Construction',
  Delivery = 'Delivery',
  EmergencyUse = 'Emergency Use',
  Freight = 'Freight',
  Parking = 'Parking',
  Permit = 'Permit',
  Rideshare = 'Rideshare',
  School = 'School',
  ServiceVehicles = 'ServiceVehicles',
  SpecialEvents = 'SpecialEvents',
  Taxi = 'Taxi',
  Utilities = 'utilities',
  Vending = 'vending',
  WasteManagement = 'WasteManagement',
  Evie = 'Evie',
  Commercial = 'Commercial',
}

export enum RateUnitPeriod {
  Rolling = 'Rolling',
  Calendar = 'Calendar',
}

export interface IRate {
  Rate: number;
  RateUnit: UnitOfTime;
  RateUnitPeriod: RateUnitPeriod | null;
  IncrementDuration: number | null;
  IncrementAmount: number | null;
  StartDuration: number | null;
  EndDuration: number | null;
}

export interface IRule {
  Activity: Activity;
  MaxStay: number | null;
  MaxStayUnit: UnitOfTime | null;
  NoReturn: number | null;
  NoReturnUnit: UnitOfTime | null;
  UserClasses: UserClass[] | null;
  Rate: IRate[] | null;
}

export interface TimeSpans {
  StartDate: Date | null;
  EndDate: Date | null;
  DaysOfWeek: DayOfWeek[] | null;
  DaysOfMonth: number[] | null;
  Months: Month[] | null;
  TimeOfDayStart: string | null;
  TimeOfDayEnd: string | null;
  DesignatedPeriod: string | null;
  DesignatedPeriodExcept: boolean | null;
}

export interface IPolicy {
  Id: number;
  PublishedDate: Date;
  Priority: number;
  Rules: IRule[];
  TimeSpans: TimeSpans[] | null;
}
